/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

@font-face {
    font-family: Helvetica;
    src: url(./Assets/fonts/HelveticaNeue.ttf);
}

* {
    font-family: Helvetica !important;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scroll-snap-stop: always;
    height: 100vh;
}

/* Custom Scroll Bar */
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    background-color: #FFFFFF;
}
::-webkit-scrollbar-thumb {
    background-color: #235B5B;
    border-radius: 4rem;
}
