.info-section {
    padding: 0 32px;
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;
    background-color: white;
    scroll-snap-align: start;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info-title-content {
    margin: 64px 0 128px 0;
}

.info-title {
    text-align: left;
    font-family: Helvetica;
    font-size: 40px;
    font-weight: bold;
    color: #235B5B;
    margin-bottom: 32px;
}

.info-title>span {
    position: relative;
    /* margin-left: 64px; */
}

.info-title span::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 4px;
    background-color: #6FB7B9;
    border-radius: 8px;
    /* left: 0; */
    bottom: -12px;
}

.vision-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vision-image {
    width: 50%;
    height: auto;
    box-shadow: -5px -5px 10px 5px grey, 5px 5px 10px 5px black;
}

.vision-text {
    width: 50%;
    margin: 64px 0 128px 64px;
}

.info-description {
    text-align: left;
    color: #235B5B;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: .6px;
    line-height: 1.4rem;
}

/* Cards */
.info-cards-content {
    margin: 64px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
}

.info-cards {
    height: auto;
    border: 2px solid #d7d3d3;
    border-radius: 12px;
    background-color: white;
    position: relative;
}

.info-card-image {
    width: 100%;
}

.info-card-title {
    margin: 32px 0 40px 0;
    color: #235B5B;
    font-family: Helvetica;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: .8px;
}

.info-card-description {
    margin: 24px;
    color: rgb(77, 75, 75);
    font-family: Helvetica;
    font-size: 18px;
    letter-spacing: .8px;
    line-height: 1.5rem;
    text-align: justify;
}

.info-card-icon {
    position: absolute;
    top: -24px;
    left: 44%;
    padding: 10px;
    background-color: #DCE6FF;
    border-radius: 30px;
}

.info-fa-icon {
    color: #2C96FF;
    font-size: 24px;
}

/* Responsive */
@media screen and (max-width: 1000px) {
    .vision-container {
        display: block;
    }

    .vision-image {
        width: 100%;
    }

    .vision-text {
        margin: 64px 0 128px 0;
        width: 100%;
    }

    .info-description {
        margin: 32px 0;
    }

    .info-title>span {
        float: none;
    }
}

@media screen and (max-width: 900px) {
    .vision-container {
        display: block;
    }

    .info-section {
        height: 120vh;
    }

    .vision-image {
        width: 100%;
        height: auto;
    }

    .vision-text {
        margin: 64px 0 128px 0;
        width: 100%;
    }

    .info-description {
        margin: 32px 0;
    }

    .info-title>span {
        float: none;
    }
}

@media screen and (max-width: 700px) {
    .info-description {
        margin: 32px 0;
    }

    .info-cards-content {
        grid-template-columns: repeat(1, 1fr);
    }

    .vision-container {
        display: block;
    }

    .info-title {
        margin-top: 50px;
    }

    .vision-image {
        width: 100%;
        height: auto;
    }

    .vision-text {
        margin: 64px 0 128px 0;
        width: 100%;
    }

    .info-title>span {
        float: none;
    }
}

@media screen and (max-width: 500px) {
    .info-description {
        margin: 32px 0;
    }

    .info-cards-content {
        grid-template-columns: repeat(1, 1fr);
    }

    .vision-container {
        display: block;
    }

    .info-title {
        margin-top: 50px;
    }

    .vision-image {
        width: 100%;
        height: auto;
    }

    .vision-text {
        margin: 64px 0 128px 0;
        width: 100%;
    }

    .info-section {
        height: 110vh;
    }

    .info-title>span {
        float: none;
    }
}