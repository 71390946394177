.story-section {
    padding-bottom: 1rem;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.sotry-container {
    background: linear-gradient(to right, #ECF2FF, #FBFCFF);
    height: 170vh;
    scroll-snap-align: start;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Image */
.story-image-content {
    width: 50%;
    max-width: 100%;
    text-align: center;
}

.story-image1 {
    width: 80%;
    height: auto;
    box-shadow: 5px 5px 10px 5px black;
}

/* Text */
.story-text-content {
    width: 50%;
}

.story-title {
    font-family: Helvetica;
    font-size: 40px;
    font-weight: bold;
    color: #235B5B;
}

.story-title>span {
    position: relative;
}

.story-title span::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 4px;
    background-color: #6FB7B9;
    border-radius: 8px;
    left: 0;
    bottom: -12px;
}

.story-description {
    margin: 32px 0;
    color: #235B5B;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: .6px;
    line-height: 1.4rem;
}

.story-text-title {
    margin: 0 0 24px 0;
    color: #235B5B;
    font-family: Helvetica;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: .7px;
}

/* Steps */
.story-text-step {
    margin: 16px 0;
}

.story-text-sTitle {
    font-family: Helvetica;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .7px;
}

.story-text-description {
    margin: 8px 24px;
    color: #235B5B;
    font-family: Helvetica;
    letter-spacing: .6px;
    line-height: 1.4rem;
}

.story-description2 {
    color: #235B5B;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: .6px;
    line-height: 1.4rem;
    padding: 0 10% 0 10%;
}

.fa-icon {
    color: #9D2553;
}

/* Responsive */
@media screen and (max-width: 1000px) {
    .story-section {
        display: block;
    }

    .story-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 32px 0;
    }
    .sotry-container {
        height: 320vh;
    }

    .story-image1 {
        width: 100%;
        height: 100%;
    }

    .story-text-content {
        width: 100%;
    }
}

/* Responsive */
@media screen and (max-width: 400px) {
    .story-section {
        display: block;
    }

    .story-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 32px 0;
    }
    .sotry-container {
        height: 420vh;
    }

    .story-image1 {
        width: 100%;
        height: 100%;
    }

    .story-text-content {
        width: 100%;
    }
}