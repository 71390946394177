.legal-text-content{
    padding: 12px 20% 32px 20%;
    background: linear-gradient(to right, #ECF2FF, #FBFCFF);
    color: #235B5B;
}
/* Title */
.legal-siteTitle a {
    text-decoration: none;
    color: white;
    font-size: 28px;
    letter-spacing: .8px;
}
.legal-siteTitle {
    padding: 20px 0 20px 0;
    border-radius: 2px;
    background-color: #3B9C9C;
    text-align: center;
    font-family: Helvetica;
}
.legal-siteSign{
    color: #54de54;
    font-family: Helvetica;
    font-size: 56px;
}
/* Info */
.legal-title {
    margin: 16px 0;
    padding: 0 0 2px 0;
    border-bottom: 2px solid grey;
    font-family: Helvetica;
    font-size: 32px;
    letter-spacing: .8px;
}
.legal-description {
    margin: 0 0 16px 0;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: .8px;
    line-height: 1.8rem;
    width: 50%;
    padding-left: 10px;
}

.legal-description1 {
    margin: 0 0 16px 0;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: .8px;
    line-height: 1.8rem;
    width: 100%;
    padding-left: 10px;
}

.legal-desc-container {
    display: flex;
    width: 100%;
}
/* Footer */
.legal-footer {
    padding: 24px 40px;
    display: block;
    color: white;
    font-family: Helvetica;
    font-size: 16px;
    text-align: center;
    letter-spacing: .8px;
    line-height: 1.6rem;
    position: relative;
    bottom: 0;
    background: #3B9C9C;
    width: 100%;
}
/* Responsive */
@media screen and (max-width: 600px) {
    .legal-section {
        padding: 24px;
    }
    .legal-siteTitle {
        padding-top: 20px;
    }
    .legal-siteTitle a {
        font-size: 32px;
    }
    .legal-title {
        font-size: 24px;
    }
    .legeal-description {
        font-size: 16px;
    }
    .legal-footer {
        padding: 12px 20px;
        text-align: center;
        font-size: 16px;
    }
    .legal-desc-container {
        display: block;
    }
    .legal-description {
        width: 100%;
    }
}
