.footer-section {
    background-color: #3B9C9C;
    scroll-snap-align: start;
}
.footer-container {
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
    color: white;
    border-bottom: 2px solid #3B9C9C;
    border-radius: 2px;
}
/* Title */
.ft-title {
    color: #1A8EFD;
    text-decoration: none;
    font-family: Helvetica;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: .8px;
}
.ft-sign {
    color: #1ECAB0;
    font-family: Helvetica;
    font-size: 40px;
    font-weight: bold;
}
.ft-description {
    width: 420px;
    margin: 16px 0 40px;
    color: #D0D3DA;
    font-family: Helvetica;
    font-size: 20px;
    letter-spacing: .8px;
    line-height: 1.7rem;
}
.footer-link {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 30px;
    font-size: 18px;
    font-weight: 500;
}

.footer-link > a, .ft-copyright > p > a {
    color: #FFFFFF;
    text-decoration: none;
}

.ft-input-title {
    color: white;
    font-family: Helvetica;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: .8px;
}
.ft-input {
    width: 300px;
    margin: 20px 8px 0 0;
    padding: 16px 22px;
    color: white;
    background-color: #293241;
    border: 1px solid transparent;
    border-radius: 50px;
    outline: transparent;
    font-family: Helvetica;
    font-size: 18px;
    letter-spacing: .8px;
}
.ft-btn {
    padding: 14px 18px;
    color: white;
    border: 2px solid transparent;
    border-radius: 50px;
    outline: transparent;
    background-color: #1A8EFD;
    font-size: 18px;
    font-family: Helvetica;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
}
.ft-btn:hover {
    color: #1A8EFD;
    background-color: white;
    border: 2px solid #1A8EFD;
}
/* Lists */
.ft-list-title {
    margin: 16px 0;
    font-family: Helvetica;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: .8px;
}
.ft-list-items {
    list-style-type: none;
}
.ft-list-items li a {
    text-decoration: none;
    color: #a5a7ac;
    padding: 0 0 2px 0;
    border-bottom: 2px dotted transparent;
    transition: border .2s ease;
}
.ft-list-items li {
    margin: 24px 0;
    font-family: Helvetica;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: .8px;
}
.ft-list-items li a:hover {
    border-bottom: 2px dotted #a5a7ac;
}
/* CopyRight */
.ft-copyright {
    padding: 24px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFFFFF;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: .8px;
}
.ft-social-links {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}
.ft-social-links li a {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #cbcdd3;
    border-radius: 50%;
    cursor: pointer;
}
.ft-social-links li a svg {
    width: 24px;
    height: 24px;
    padding: 1px;
}
.ft-social-links li a svg path {
    fill: #cbcdd3;
}
.ft-social-links li a:hover, .ft-social-links li a:hover svg path {
    fill: #1A8EFD;
    border: 2px solid #1A8EFD;
}
/* Responsive */
@media screen and (max-width: 700px) {
    .footer-container {
        padding: 24px;
    }
    .ft-description {
        width: 100%;
        margin: 16px 0 24px;
    }
    .ft-input {
        width: 100%;
        margin: 16px 0;
    }
    .ft-btn {
        width: 100%;
        margin: 0 0 16px;
    }
    .ft-copyright {
        padding: 18px;
        display: block;
        text-align: center;
    }
    .ft-social-links {
        margin: 16px 0 0;
    }
}
