.ba-section {
    padding: 32px;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    background: linear-gradient(to right, #ECF2FF, #FBFCFF);
    scroll-snap-align: start;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

/* Image */
.ba-image-content {
    width: 50%;
    max-width: 100%;
    text-align: center;
}

.ba-image1 {
    width: 80%;
    height: auto;
    box-shadow: 5px 5px 10px 5px black;
}

/* Text */
.ba-text-content {
    width: 50%;
}

.ba-title {
    font-family: Helvetica;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: .7px;
    color: #235B5B;
}

.ba-title>span {
    position: relative;
}

.ba-title span::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 4px;
    background-color: #6FB7B9;
    border-radius: 8px;
    left: 0;
    bottom: -12px;
}

.ba-description {
    margin: 32px 0;
    color: #235B5B;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: .7px;
    line-height: 1.5rem;
}

/* Checks */
.ba-checks {
    margin: 28px 0;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: .7px;
    color: #235B5B;
}

.ba-checks > a {
    color: #235B5B;
    text-decoration: none;
    font-weight: bold;
}

.ba-check-first {
    margin-top: 40px;
}

.ba-check-last {
    margin-bottom: 40px;
}

/* Book Appointment Button */
.ba-appointment-btn {
    padding: 18px 24px;
    color: white;
    border: 1px solid transparent;
    border-radius: 50px;
    outline: transparent;
    background-color: #1A8EFD;
    font-size: 20px;
    font-family: Helvetica;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
}

.ba-appointment-btn:hover {
    color: #1A8EFD;
    background-color: transparent;
    border: 1px solid #1A8EFD;
}

/* Responsive */
@media screen and (max-width: 1000px) {
    .ba-section {
        display: block;
        height: 140vh;
    }

    .ba-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 32px 0;
    }

    .ba-text-content {
        width: 100%;
    }

    .ba-image1 {
        width: 80%;
        height: 80%;
    }
}

@media screen and (max-width: 900px) {
    .ba-section {
        display: block;
        height: 120vh;
    }

    .ba-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 32px 0;
    }

    .ba-text-content {
        width: 100%;
    }

    .ba-image1 {
        width: 80%;
        height: 80%;
    }
}